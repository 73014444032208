<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>In koor zingen wij</b-card-title>
                <b-card-text>
                    <silent-box class="galleryitems" :gallery="galleryvideos"></silent-box>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Koor',
        data: function() {
            return {
                galleryvideos: [
                {
                    src: 'https://www.youtube.com/embed/n_UMVTtd7fI',
                    // thumbnail: 'https://i.ytimg.com/vi_webp/4HT4sLyL86E/maxresdefault.webp',
                    thumbnailWidth: '100%',
                    autoplay: 'True',
                }
                ],
            }
        }
    }
</script>

<style>



</style>